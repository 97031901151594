import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const PublicationsPage = (): JSX.Element => {
  const Citation = ({
    author,
    title,
    publisher,
    link,
  }: {
    author: string;
    title: string;
    publisher: string;
    link?: string;
  }) => {
    if (link) {
      return (
        <p>
          {author}. <a href={link}>{title}</a>
          {title.endsWith("?") ? null : "."} <em>{publisher}</em>.
        </p>
      );
    } else {
      return (
        <p>
          {author}. {title}
          {title.endsWith("?") ? null : "."} <em>{publisher}</em>.
        </p>
      );
    }
  };

  // Render
  return (
    <Layout>
      <SEO title="Publications" />
      <RunReCaptcha action="publications" />
      <div className={gutter}>
        <h1>Publications</h1>
        <p>
          NanoPharmaceuticals’ scientists, either individually or collectively,
          have written over 1,000 peer-reviewed articles. Below is a list of the
          24 most relevant publications relating to NanoPharmaceuticals’ current
          activities.
        </p>

        <h2>Background Information</h2>
        <Citation
          author="Davis PJ, et al"
          title="Nongenomic Actions of Thyroid Hormone: The Integrin Component"
          publisher="Physiol Rev. 2021 Jan 1;101(1):319-352"
          link="https://pubmed.ncbi.nlm.nih.gov/32584192/"
        />
        <Citation
          author="Sudha T, et al"
          title="Nano-Targeting of Thyrointegrin αvβ3 Receptor in Solid Tumors and Impact on Radiosensitization"
          publisher="Radiation Res. 2021 Jul 14. doi: 10.1667/RADE-21-00031.1"
          link="https://doi.org/10.1667/RADE-21-00031.1"
        />
        <Citation
          author="Davis PJ, et al"
          title="Coronaviruses and Integrin αvβ3: Does Thyroid Hormone Modify the Relationship?"
          publisher="Endocr Res. 2020 Aug;45(3):210-215"
          link="https://pubmed.ncbi.nlm.nih.gov/32628899/"
        />
        <Citation
          author="Debreli Coskun M, et al"
          title="αvβ3 Integrin Antagonists Enhance Chemotherapy Response in an Orthotopic Pancreatic Cancer Model"
          publisher="Front Pharmacol. 2020 Feb 27;11:95"
          link="https://pubmed.ncbi.nlm.nih.gov/32174830/"
        />
        <Citation
          author="Sami SA, et al"
          title="Current and Future Molecular Targets for Acute Myeloid Leukemia Therapy"
          publisher="Curr Treat Options Oncol. 2020 Jan 13;21(1):3. doi: 10.1007/s11864-019-0694-6"
          link="https://pubmed.ncbi.nlm.nih.gov/31933183/"
        />
        <Citation
          author="Pastor ER, Mousa SA"
          title="Current management of neuroblastoma and future direction"
          publisher="Crit Rev Oncol Hematol. 2019 Jun;138:38-43"
          link="https://pubmed.ncbi.nlm.nih.gov/31092383/"
        />
        <Citation
          author="Rajabi M, et al"
          title="Synthesis of new analogs of tetraiodothyroacetic acid (tetrac) as novel angiogenesis inhibitors for treatment of cancer"
          publisher="Bioorganic &#38; medicinal chemistry letters. 2018;28(7):1223-7"
          link="https://doi.org/10.1016/j.bmcl.2018.02.045"
        />
        <Citation
          author="Mousa SA, et al"
          title="Contributions of thyroid hormone to cancer metastasis"
          publisher="Biomedicines. 2018;6(3):89"
          link="https://doi.org/10.3390/biomedicines6030089"
        />

        <h2>TAT Molecules in GBM</h2>
        <Citation
          author="Rajabi M, et al"
          title="Triazole Modified Tetraiodothyroacetic Acid Conjugated to Polyethylene Glycol: High Affinity Thyrointegrin αvβ3 Antagonist with Potent Anticancer Activities in Glioblastoma Multiforme"
          publisher="Bioconjug Chem. 2019 Dec 18;30(12):3087-3097"
          link="https://pubmed.ncbi.nlm.nih.gov/31714064/"
        />
        <Citation
          author="Hay B, et al"
          title="New Thyrointegrin αvβ3 Antagonist with a Scalable Synthesis, Brain Penetration, and Potent Activity against Glioblastoma Multiforme"
          publisher="J. Medicinal Chemistry; 2021 Apr 22. doi: 10.1021/acs.jmedchem.1c00350"
          link="https://doi.org/10.1021/acs.jmedchem.1c00350"
        />
        <Citation
          author="Mousa, S., et al"
          title="Novel thyrointegrin αvβ3 antagonist in the treatment of glioblastoma multiforme"
          publisher="Cancer Research, 2019; 79: p. 1300"
          link="https://doi.org/10.1158/1538-7445.AM2019-1300"
        />
        <Citation
          author="Godugu K, et al"
          title="Anti-Cancer Activities of Thyrointegrin αvβ3 Antagonist Mono- and Bis-Triazole Tetraiodothyroacetic Acid Conjugated via Polyethylene Glycols in Glioblastoma"
          publisher="Cancers (Basel). 2021 Jun 3;13(11):2780. doi: 10.3390/cancers13112780"
          link="https://doi.org/10.3390/cancers13112780"
        />

        <h2>Neuroendocrine Cancer – Neuroblastoma (BG-P-TAT)</h2>
        <Citation
          author="Karakus OO, et al"
          title="Design, synthesis, and biological evaluation of novel bifunctional thyrointegrin antagonists for neuroblastoma"
          publisher="Bioorg Med Chem. 2021 Jul 15;42:116250. doi: 10.1016/j.bmc.2021.116250"
          link="https://doi.org/10.1016/j.bmc.2021.116250"
        />
        <Citation
          author="Karakus OO, et al"
          title="Dual Targeting of Norepinephrine Transporter (NET) Function and Thyrointegrin αvβ3 Receptors in the Treatment of Neuroblastoma"
          publisher="J Med Chem. 2020 Jul 23;63(14):7653-7662"
          link="https://pubmed.ncbi.nlm.nih.gov/32571015/"
        />
        <Citation
          author="Karakus OO, et al"
          title="Discovery of Dual Targeting PEGylated BG-P1600-TAT to Norepinephrine Transporter (NET) and Thyrointegrin αvβ3 in the Treatment of Neuroblastoma: Broad Spectrum Anti-Angiogenesis and Anti-Cancer Activities"
          publisher="Bioorganic &#38; Medicinal Chemistry; 2021 August 1;43:116278"
          link="https://doi.org/10.1016/j.bmc.2021.116278"
        />

        <h2>TAT Molecules in AML</h2>
        <Citation
          author="Darwish NE, et al"
          title="Novel Thyrointegrin αvβ3 Antagonist (fb-PMT) with Potent Affinity in the Treatment of Acute Myeloid Leukemia"
          publisher="Blood Advances 2021; under review"
        />
        <Citation
          author="Mousa SA, et al"
          title="Novel Thyrointegrin αvβ3 Antagonist (fb-PMT) for the Effective Management of Acute Myeloid Leukemia"
          publisher="Blood.2020; 136 (Supplement 1): 5. doi: 10.1182/blood-2020-141628"
          link="https://doi.org/10.1182/blood-2020-141628"
        />
        <Citation
          author="Sudha T, et al"
          title="Novel Polyethylene Glycol-Conjugated Triazole Derivative with High Thyrointegrin αvβ3 Affinity in Acute Myeloid Leukemia Management"
          publisher="Cancers; 13(16):4070. doi: 10.3390/cancers13164070"
          link="https://doi.org/10.3390/cancers13164070"
        />

        <h2>Nano-Targeted Delivery</h2>
        <Citation
          author="Godugu K, et al"
          title="Nano Diaminopropane tetrac and integrin αvβ3 expression in different cancer types: Anti-cancer efficacy and safety"
          publisher="Cancer Treat Res Commun. 2021 May 13;28:100395. doi: 10.1016/j.ctarc.2021.100395"
          link="https://doi.org/10.1016/j.ctarc.2021.100395"
        />
        <Citation
          author="Sudha T, et al."
          title="Nano-Targeting of Thyrointegrin αvβ3 Receptor in Solid Tumors and Impact on Radiosensitization"
          publisher="Radiation Res. 2021 Jul 14. doi: 10.1667/RADE-21-00031.1"
          link="https://doi.org/10.1667/RADE-21-00031.1"
        />
        <Citation
          author="Karakus OO, et al"
          title="Norepinephrine Transporter Analogue Benzylguanidine Conjugated Nanoparticles in the Treatment of Neuroblastoma"
          publisher="Nanomedicine; 2021 Oct 15"
          link="https://doi.org/10.2217/nnm-2021-0230"
        />
        <Citation
          author="Li W, et al"
          title="Pharmacokinetics, Biodistribution, and Anti-Angiogenesis Efficacy of Diamino Propane Tetraiodothyroacetic Acid-conjugated Biodegradable Polymeric Nanoparticle"
          publisher="Sci Rep. 2019 Jun 21;9(1):9006"
          link="https://pubmed.ncbi.nlm.nih.gov/31227723/"
        />
        <Citation
          author="Sudha T, et al"
          title="Targeted delivery of paclitaxel and doxorubicin to cancer xenografts via the nanoparticle of nano-diamino-tetrac"
          publisher="Int J Nanomedicine. 2017;12:1305-1315"
          link="https://pubmed.ncbi.nlm.nih.gov/28243091/"
        />
        <Citation
          author="Sudha T, et al"
          title="Targeted delivery of cisplatin to tumor xenografts via the nanoparticle component of nano-diamino-tetrac"
          publisher="Nanomedicine (Lond). 2017 Feb;12(3):195-205"
          link="https://pubmed.ncbi.nlm.nih.gov/28102776/"
        />
      </div>
    </Layout>
  );
};

export default PublicationsPage;
